import { useEnv } from '../EnvContext/EnvContext'

import './PageHeading.css'

/**
 * Page heading component
 */
const PageHeading: preact.FunctionComponent<{
  /** Heading */
  label: string,
  /** Features list */
  features?: string[],
}> = ({
  label,
  features = [],
}) => {
  const { DEV } = useEnv()

  // Show only in dev mode
  if (!DEV) {
    return null
  }

  return (
    <div className="iyp-page-heading">
      <h2 className="iyp-h2">
        { label }
      </h2>
      {features.length !== 0 &&
        <ul className="iyp-list">
          {features.map((item, index) =>
            <li key={ index }>
              { item }
            </li>
          )}
        </ul>
      }
    </div>
  )
}

export default PageHeading
