/**
 * Fullscreen control tweaked to be able to pass fullscreenElement as an option
 * @link https://github.com/Leaflet/Leaflet.fullscreen
 *
 * Note: Doesn't work in iPhone (iOS < 15.4)
 * @link https://caniuse.com/fullscreen
 */
import {
  Map,
  Browser,
  Control,
  DomUtil,
  DomEvent,
  bind,

  FullscreenXOptions,
} from 'leaflet'

import FullscreenIconHtml from './fullscreen.svg?raw'

import './FullscreenX.css'

export default class FullscreenX extends Control {
  /** @inheritdoc */
  public declare options: FullscreenXOptions

  protected declare link: HTMLAnchorElement
  protected declare _map: Map

  /**
   * Is suppoerted
   * <iframe allow="fullscreen" />
   * Note: N/A on iPhone
   * @link https://caniuse.com/mdn-api_document_fullscreenenabled
   */
  public static isAvailable: boolean = (
    document.fullscreenEnabled === true ||
    document.webkitFullscreenEnabled === true ||
    // N/A on iPhone, assume it's enabled
    (document.fullscreenEnabled === undefined && document.webkitFullscreenEnabled === undefined)
  )

  /**
   * @inheritdoc
   */
  constructor(options: Partial<FullscreenXOptions> | undefined) {
    super({
      position: 'topleft',
      title: {
        false: 'View Fullscreen',
        true: 'Exit Fullscreen',
      },
      pseudoFullscreen: false,
      target: undefined,
      ...options,
    })
  }

  /**
   * @inheritdoc
   */
  public onAdd(map: Map) {
    const container = DomUtil.create('div', 'leaflet-control-fullscreen leaflet-bar leaflet-control')

    this.link = DomUtil.create('a', 'leaflet-control-fullscreen-button leaflet-bar-part', container)
    this.link.href = '#'
    this.link.innerHTML = FullscreenIconHtml

    this._map = map
    this._map.on('fullscreenchange', this._toggleTitle, this)

    // Add control as a backreference when initializing with new so it's options are available
    if (!map.fullscreenXControl) {
      map.fullscreenXControl = this
    }

    this._toggleTitle()

    DomEvent.on(this.link, 'click', this._click, this)

    return container
  }

  /**
   * Click handler
   */
  protected _click(event: Event): void {
    /**
     * Workaround for leaflet bug 1.7.1 that manifests itself by emitting event 2x
     * @link https://github.com/Leaflet/Leaflet/issues/7255
     * Resolved in leaflet 1.8
     */
    if (Browser.safari && Browser.mobile && !event.isTrusted) {
      return
    }

    DomEvent.stopPropagation(event)
    DomEvent.preventDefault(event)

    this._map.toggleFullscreenX(this.options)
  }

  /**
   * Toggle title on/ off based on fullscreen state
   */
  protected _toggleTitle(): void {
    this.link.title = this.options.title![
      this._map.isFullscreenX() ? 'true' : 'false'
    ]
  }
}

// Define shortcut
Control.FullscreenX = FullscreenX

/**
 * Include map methods
 */
Map.include({
  /**
   * Check if fullscreen is on
   */
  isFullscreenX (this: Map): boolean {
    return this._isFullscreenX ?? false
  },

  /**
   * Toggle fullscreen
   * Simulate iPhone behaviour for tests
   * ```js
   * document.body.requestFullscreen = document.body.webkitRequestFullscreen = undefined
   * document.exitFullscreen = document.webkitExitFullscreen = undefined
   * ```
   */
  toggleFullscreenX (this: Map, options: FullscreenXOptions): void {
    const target = options.target ?? this.getContainer()

    // Off
    if (this.isFullscreenX()) {
      if (options && options.pseudoFullscreen) {
        this._disablePseudoFullscreen(target)
      } else if (document.exitFullscreen) {
        document.exitFullscreen()
      // Safari
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      // iPhone (problem)
      } else {
        this._disablePseudoFullscreen(target)
      }
    // On
    } else {
      // eslint-disable-next-line no-lonely-if
      if (options && options.pseudoFullscreen) {
        this._enablePseudoFullscreen(target)
      } else if (target.requestFullscreen) {
        /**
         * Note: When inside iframe and fs is not allowed, error is thrown `TypeError: fullscreen error`
         * @link https://developer.mozilla.org/en-US/docs/Web/API/Element/requestFullScreen#exceptions
         */
        target.requestFullscreen()
          .catch(err => alert(`An error occurred while trying to switch into fullscreen mode: ${err.message} (${err.name})`))
      // Safari
      } else if (target.webkitRequestFullscreen) {
        target.webkitRequestFullscreen()
      // iPhone
      } else {
        this._enablePseudoFullscreen(target)
      }
    }
  },

  /**
   * Toggle pseudo fullscreen on
   */
  _enablePseudoFullscreen (this: Map, container: HTMLElement): void {
    DomUtil.addClass(container, 'leaflet-pseudo-fullscreenx')
    this._setFullscreenX(true)
    this.fire('fullscreenchange')
  },

  /**
   * Toggle pseudo fullscreen off
   */
  _disablePseudoFullscreen (this: Map, container: HTMLElement): void {
    DomUtil.removeClass(container, 'leaflet-pseudo-fullscreenx')
    this._setFullscreenX(false)
    this.fire('fullscreenchange')
  },

  /**
   * Update container to reflect fullscreen state
   */
  _setFullscreenX (this: Map, isFullscreen: boolean): void {
    this._isFullscreenX = isFullscreen

    const container = this.getContainer()

    if (isFullscreen) {
      DomUtil.addClass(container, 'leaflet-fullscreen-on')
    } else {
      DomUtil.removeClass(container, 'leaflet-fullscreen-on')
    }

    this.invalidateSize()
  },

  /**
   * Handle fullscreen change event
   */
  _onFullscreenXChange (this: Map, event: Event): void {
    const fullscreenElement =
      document.fullscreenElement ||
      document.webkitFullscreenElement

    const target = this.fullscreenXControl?.options.target ?? this.getContainer()

    if (fullscreenElement === target && !this._isFullscreenX) {
      this._setFullscreenX(true)
      this.fire('fullscreenchange')
    } else if (fullscreenElement !== target && this._isFullscreenX) {
      this._setFullscreenX(false)
      this.fire('fullscreenchange')
    }
  }
})

// Add map options
Map.mergeOptions({
  fullscreenXControl: false,
})


/**
 * Init hook
 */
Map.addInitHook(function (this: Map) {
  // Auto initialize control when using map option
  if (this.options.fullscreenXControl) {
    this.fullscreenXControl = new FullscreenX(
      this.options.fullscreenXControl === true
        ? undefined
        : this.options.fullscreenXControl
    )

    this.addControl(this.fullscreenXControl)
  }

  // Resolve event name
  let fullscreenchange: string | undefined

  if ('onfullscreenchange' in document) {
    fullscreenchange = 'fullscreenchange'
  } else if ('onwebkitfullscreenchange' in document) {
    fullscreenchange = 'webkitfullscreenchange'
  }

  // Subscribe/ unsubscribe to event
  if (fullscreenchange) {
    const onFullscreenXChange = bind(this._onFullscreenXChange, this)

    this.whenReady(() =>
      DomEvent.on(document as unknown as HTMLElement, fullscreenchange!, onFullscreenXChange)
    )

    this.on('unload', () =>
      DomEvent.off(document as unknown as HTMLElement, fullscreenchange!, onFullscreenXChange)
    )
  }
})
